import { localizedString } from '@languages';
import { getResidentalAddressLabel } from '@lib/addressfieldLabel';
import { isIdCard, isProofOfAgeDocument } from '@lib/constants/documents';
import {
  FLOW_V2_DIGITAL_DL_HIDE_CARD_NUMBER_FIELD,
  LICENCEVERSION,
  REMOVE_ADDRESS_REVIEW,
  TURKISH_LICENCE_B_CLASS_ENABLED,
  ENABLE_VISA_AFTER_PASSPORT,
  VISA_AFTER_PASSPORT_ADDITIONAL_FIELDS
} from '@spotMobileConfig';

import { isCardNumberFieldVisible } from '../../cardNumberField';

const COUNTRY_NAMES_FOR_VARIANTS = {
  AUSTRALIA: 'AUSTRALIA',
  BRAZIL: 'BRAZIL',
  NEW_ZEALAND: 'NEW ZEALAND',
  TURKEY: 'TURKEY',
  HONG_KONG: 'HONG KONG',
  SINGAPORE: 'SINGAPORE'
};

export const getFields = ({
  idType,
  countryOfIssue = '',
  addressData,
  useIdNumber,
  disabledFields = [],
  values = {},
  isOldTurkishDL
}) => {
  const upperCasedCountryOfIssue = countryOfIssue.toUpperCase();

  const fields = [
    { id: 'firstName', label: localizedString('firstName') },
    { id: 'middleName', label: localizedString('middleName') },
    { id: 'lastName', label: localizedString('surname') }
  ];

  if (!idType.match(/MEDICARE/i)) {
    fields.push({
      id: 'dateOfBirth',
      label: localizedString('dateOfBirth'),
      date: true
    });
  }

  /**
   * if ID type is Passport, it should display the Passport Number field
   * instead of the Licence Number and Licence Version.
   */
  if (idType.match(/PASSPORT/i)) {
    fields.push({
      id: 'idNumber',
      label: localizedString('idNumber')
    });
  } else if (idType.match(/LICENCE/i)) {
    fields.push({
      id: 'idNumber',
      label: localizedString('idNumber')
    });

    if (
      isCardNumberFieldVisible({
        idType,
        countryOfIssue,
        skipDigitalDriverLicence: FLOW_V2_DIGITAL_DL_HIDE_CARD_NUMBER_FIELD
      })
    ) {
      fields.push({
        id: 'cardNumber',
        label: localizedString('cardNumber')
      });
    }

    if (LICENCEVERSION && upperCasedCountryOfIssue === COUNTRY_NAMES_FOR_VARIANTS.NEW_ZEALAND) {
      fields.push({
        id: 'licenceVersion',
        type: 'number',
        label: localizedString('licenceVersion')
      });
    }

    if (shouldShowAdditionalTurkishDLFields(idType, upperCasedCountryOfIssue)) {
      fields.push({
        id: 'documentNumber',
        label: localizedString('trDlDocumentNumber')
      });
      if (!isOldTurkishDL) {
        fields.push({
          id: 'dlclassCodeBFrom',
          label: localizedString('bClassIssueDate'),
          date: true
        });
      }
    }
  } else if (!useIdNumber) {
    fields.push({
      id: 'cardNumber',
      label: localizedString('cardNumber')
    });
  } else {
    fields.push({
      id: 'idNumber',
      label: localizedString('idNumber')
    });
  }

  if (
    (isIdCard(idType) || idType.match(/LICENCE/i)) &&
    upperCasedCountryOfIssue === COUNTRY_NAMES_FOR_VARIANTS.BRAZIL
  ) {
    fields.push(
      {
        id: 'taxNumber',
        label: localizedString('taxNumber')
      },
      {
        id: 'documentNumber',
        label: localizedString('documentNumber')
      }
    );
  }

  if (
    (idType.match(/LICENCE/i) &&
      !isOldTurkishDL &&
      upperCasedCountryOfIssue !== COUNTRY_NAMES_FOR_VARIANTS.SINGAPORE) ||
    idType.match(/PASSPORT/i) ||
    idType.match(/IMMI/i) ||
    (isProofOfAgeDocument(idType) &&
      ![
        COUNTRY_NAMES_FOR_VARIANTS.BRAZIL,
        COUNTRY_NAMES_FOR_VARIANTS.HONG_KONG,
        COUNTRY_NAMES_FOR_VARIANTS.SINGAPORE
      ].includes(upperCasedCountryOfIssue)) ||
    values.expiryDate
  ) {
    if (shouldShowAdditionalTurkishDLFields(idType, upperCasedCountryOfIssue)) {
      fields.push({
        id: 'expiryDate',
        label: localizedString('bClassExpiryDate'),
        date: true
      });
    } else {
      fields.push({
        id: 'expiryDate',
        label: localizedString('dateOfExpiry'),
        date: true
      });
    }
  }

  if (!REMOVE_ADDRESS_REVIEW) {
    fields.push({
      id: 'addressData',
      label: getResidentalAddressLabel(),
      addressFinder: true
    });
  }

  if (idType.match(/PASSPORT/i)) {
    // If visa then add following field
    if (
      ENABLE_VISA_AFTER_PASSPORT &&
      upperCasedCountryOfIssue !== COUNTRY_NAMES_FOR_VARIANTS.NEW_ZEALAND &&
      upperCasedCountryOfIssue !== COUNTRY_NAMES_FOR_VARIANTS.AUSTRALIA
    ) {
      for (const visaField of VISA_AFTER_PASSPORT_ADDITIONAL_FIELDS) {
        const { id, date } = visaField;
        fields.push({
          id,
          label: localizedString(id),
          date
        });
      }
    }
  }

  // Set input default value
  return fields.map((field) => {
    let value = values[field.id] || '';
    let disabled = false;

    if (field.id === 'addressData') {
      value = addressData;
    } else {
      disabled = disabledFields.includes(field.id);
    }

    return {
      ...field,
      value,
      disabled
    };
  });
};

function shouldShowAdditionalTurkishDLFields(idType = '', upperCasedCountryOfIssue = '') {
  return (
    TURKISH_LICENCE_B_CLASS_ENABLED &&
    idType.match(/LICENCE/i) &&
    upperCasedCountryOfIssue === COUNTRY_NAMES_FOR_VARIANTS.TURKEY
  );
}
